import { createModule } from "@/di/module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplayVehicleComponent from "@/app/sub-modules/vehicle/components/display-vehicle.component.vue";
import DisplayVehicleViewComponent from "@/app/sub-modules/vehicle/components/display-vehicle-view.component.vue";
import EditVehicleComponent from "@/app/sub-modules/vehicle/components/edit-vehicle.component.vue";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
export const VehicleSubModule = (domainId, options) => {
    const id = "vehicle";
    const moduleId = options?.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditVehicleComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayVehicleComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayVehicleViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "local_shipping",
                showChangeHistory: true,
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.vehicle" }],
    });
};
