import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-70bcdc30"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["data-qa-id"];
const _hoisted_2 = { class: "d-flex gap-3" };
const _hoisted_3 = { class: "title-wrapper" };
const _hoisted_4 = { class: "d-flex gap-3" };
const _hoisted_5 = { class: "modules-details" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageTitle = _resolveComponent("PageTitle");
    const _component_FactSheetPageHeader = _resolveComponent("FactSheetPageHeader");
    const _component_EntityLabel = _resolveComponent("EntityLabel");
    const _component_ConnectStatus = _resolveComponent("ConnectStatus");
    const _component_SiteType = _resolveComponent("SiteType");
    const _component_Id = _resolveComponent("Id");
    const _component_Icon = _resolveComponent("Icon");
    const _component_AppButton = _resolveComponent("AppButton");
    const _component_PageHeader = _resolveComponent("PageHeader");
    const _component_PanelTopNavigation = _resolveComponent("PanelTopNavigation");
    const _component_RouterView = _resolveComponent("RouterView");
    const _component_AppPanel = _resolveComponent("AppPanel");
    const _directive_tooltip = _resolveDirective("tooltip");
    return (_openBlock(), _createElementBlock("div", {
        class: "entity-details",
        "data-qa-id": _ctx.$options.name
    }, [
        _createVNode(_component_PageHeader, {
            "is-loading": _ctx.isLoading,
            "with-subtitle": ""
        }, _createSlots({ _: 2 }, [
            (_ctx.entity)
                ? {
                    name: "title",
                    fn: _withCtx(() => [
                        (_ctx.isFactSheetsDomain)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_PageTitle, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate("domains.fact-sheets.page-header.title")), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_FactSheetPageHeader, { entity: _ctx.entity }, null, 8, ["entity"])
                            ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_EntityLabel, {
                                        name: _ctx.translate('name'),
                                        isGray: _ctx.isBPCActive
                                    }, null, 8, ["name", "isGray"]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity?.productTypes, (product) => {
                                        return (_openBlock(), _createElementBlock("div", { key: product }, [
                                            _createVNode(_component_EntityLabel, {
                                                name: _ctx.productTypeInfo(product),
                                                isGray: ""
                                            }, null, 8, ["name"])
                                        ]));
                                    }), 128))
                                ]),
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_PageTitle, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.entity?.name), 1)
                                        ]),
                                        _: 1
                                    }),
                                    (_ctx.isConnectingEnabled)
                                        ? (_openBlock(), _createBlock(_component_ConnectStatus, {
                                            key: 0,
                                            connectedEntity: _ctx.connectedEntity
                                        }, null, 8, ["connectedEntity"]))
                                        : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("span", _hoisted_4, [
                                    (_ctx.siteType())
                                        ? (_openBlock(), _createBlock(_component_SiteType, { key: 0 }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.siteType()), 1)
                                            ]),
                                            _: 1
                                        }))
                                        : _createCommentVNode("", true),
                                    _createVNode(_component_Id, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.entity?.id), 1)
                                        ]),
                                        _: 1
                                    })
                                ])
                            ], 64))
                    ]),
                    key: "0"
                }
                : undefined,
            (!_ctx.isForbidden(_ctx.errorResponse))
                ? {
                    name: "buttons",
                    fn: _withCtx(() => [
                        (_ctx.isCopyModuleAvailable() && _ctx.canEdit && _ctx.hasWriteAccess)
                            ? _withDirectives((_openBlock(), _createBlock(_component_AppButton, {
                                key: 0,
                                class: "p-button p-button-secondary header-button",
                                onClick: _ctx.openCopyModuleModal
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "file_copy" })
                                ]),
                                _: 1
                            }, 8, ["onClick"])), [
                                [
                                    _directive_tooltip,
                                    _ctx.getCopyModuleTooltip(),
                                    void 0,
                                    { bottom: true }
                                ]
                            ])
                            : _createCommentVNode("", true),
                        ((_ctx.hasStaticOriginDomain || _ctx.hasDynamicOriginDomain) &&
                            _ctx.roleCheck(_ctx.$permissions.belongsToCompany, _ctx.entity?.companyId))
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 1,
                                class: "p-button p-button-secondary header-button",
                                onClick: _ctx.navigateToOriginDomain
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "input" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.getDestinationTranslateKey())), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                        (_ctx.hasWriteAccess && !!_ctx.crossDomainId && _ctx.isBPCActive)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 2,
                                class: "p-button p-button-secondary header-button",
                                onClick: _ctx.openCrossDomainEntityModal
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "add" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.getCrossDomainCreateBtnTranslateKey())), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                        (_ctx.canEdit && _ctx.hasWriteAccess && !_ctx.isFactSheetsDomain)
                            ? (_openBlock(), _createBlock(_component_AppButton, {
                                key: 3,
                                class: "p-button p-button-secondary header-button",
                                "data-qa-id": "edit-button",
                                onClick: _ctx.navigateToEditPage
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Icon, { name: "edit" }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translate("buttons.edit")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                    ]),
                    key: "1"
                }
                : undefined
        ]), 1032, ["is-loading"]),
        _createVNode(_component_PanelTopNavigation, {
            "is-loading": _ctx.isLoading,
            "menu-items": _ctx.menuItems,
            skeletons: _ctx.modules.length
        }, null, 8, ["is-loading", "menu-items", "skeletons"]),
        _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppPanel, null, {
                default: _withCtx(() => [
                    _createVNode(_component_RouterView)
                ]),
                _: 1
            })
        ])
    ], 8, _hoisted_1));
}
