import FactSheetContextMenuHeader from "@/app/domains/verification-requests/fact-sheets/components/fact-sheet-context-menu-header.component.vue";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { FactSheetModuleId, FactSheetsDomainId, SiteSeparator, UnloadingPointSeparator, } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
import { CompanyInformationSubModule } from "@/app/sub-modules/company-information/company-information.module";
import { PostalAddressSubModule } from "@/app/sub-modules/postal-address/postal-address.module";
import { AccessRestrictionsSubModule } from "@/app/sub-modules/access-restrictions/access-restrictions.module";
import { SiteAdditionalInformationSubModule } from "@/app/sub-modules/additional-information/site/site-additional-information.module";
import { AlarmsAndEmergencySubModule } from "@/app/sub-modules/alarms-and-emargency/alarms-and-emargency.module";
import { CheckInSubModule } from "@/app/sub-modules/check-in/check-in.module";
import { GatesSubModule } from "@/app/sub-modules/gates/gates.module";
import { GeneralSafetyRulesSubModule } from "@/app/sub-modules/general-safety-rules/general-safety-rules.module";
import { InfrastructuresNearbySubModule } from "@/app/sub-modules/infrastructures-nearby/infrastructures-nearby.module";
import { OpeningHoursSubModule } from "@/app/sub-modules/opening-hours/opening-hours.module";
import { ParkingSpaceBasicSubModule } from "@/app/sub-modules/parking-space-basic/parking-space-basic.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { SafetyTestSubModule } from "@/app/sub-modules/safety-test/safety-test.module";
import { SiteIncidentManagementSubModule } from "@/app/sub-modules/site-incident-management/site-incident-management.module";
import { SiteNavigationSubModule } from "@/app/sub-modules/site-navigation/site-navigation.module";
import { TrafficRelatedRulesSubModule } from "@/app/sub-modules/traffic-related-rules/traffic-related-rules.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { WeighingBridgesSubModule } from "@/app/sub-modules/weighing-bridges/weighing-bridges.module";
import { AvailableEquipmentPackedSubModule } from "@/app/sub-modules/available-equipment-packed/available-equipment-packed.module";
import { AvailableEquipmentSubModule } from "@/app/sub-modules/available-equipment/available-equipment.module";
import { UlpBasicInformationSubModule } from "@/app/sub-modules/basic-information/un-loading-point/ulp-basic-information.module";
import { ContactDataSubModule } from "@/app/sub-modules/contact-data/contact-data.module";
import { ReceivingTankSubModule } from "@/app/sub-modules/receiving-tank/receiving-tank.module";
import { RegistrationSubModule } from "@/app/sub-modules/registration/registration.module";
import { ShipmentPreNotificationSubModule } from "@/app/sub-modules/shipment-pre-notification/shipment-pre-notification.module";
import { SlotBookingSubModule } from "@/app/sub-modules/slot-booking/slot-booking.module";
import { UnLoadingAreaSubModule } from "@/app/sub-modules/un-loading-area/un-loading-area.module";
import { WorkingOnTopSubModule } from "@/app/sub-modules/working-on-top/working-on-top.module";
import { EntitySeparatorSubModule } from "@/app/sub-modules/separators/entity-separator/entity-separator.module";
import { CargoSecuringSubModule } from "@/app/sub-modules/cargo-securing/cargo-securing.module";
import { CompressorSubModule } from "@/app/sub-modules/compressor/compressor.module";
import { ConnectionsSubModule } from "@/app/sub-modules/connections/connections.module";
import { DocumentsSubModule } from "@/app/sub-modules/documents/documents.module";
import { EquipmentPackedSubModule } from "@/app/sub-modules/equipment-packed/equipment-packed.module";
import { FillingSubModule } from "@/app/sub-modules/filling/filling.module";
import { GeneralInstructionsSubModule } from "@/app/sub-modules/general-instructions/general-instructions.module";
import { HoseSubModule } from "@/app/sub-modules/hose/hose.module";
import { PackagingSubModule } from "@/app/sub-modules/packaging/packaging.module";
import { PalletsSubModule } from "@/app/sub-modules/pallets/pallets.module";
import { PumpSubModule } from "@/app/sub-modules/pump/pump.module";
import { SamplingSubModule } from "@/app/sub-modules/sampling/sampling.module";
import { SealingSubModule } from "@/app/sub-modules/sealing/sealing.module";
import { TankSubModule } from "@/app/sub-modules/tank/tank.module";
import { TemperatureRequirementsSubModule } from "@/app/sub-modules/temperature-requirements/temperature-requirements.module";
import { VapourReturnSubModule } from "@/app/sub-modules/vapour-return/vapour-return.module";
import { VehicleSubModule } from "@/app/sub-modules/vehicle/vehicle.module";
import { WeighingSubModule } from "@/app/sub-modules/weighing/weighing.module";
import { FactSheetModule } from "@/app/modules/fact-sheets/fact-sheet.module";
export const FactSheetsModule = createModule({
    components: [FactSheetContextMenuHeader],
    imports: [
        DomainModule({
            id: FactSheetsDomainId,
            icon: "sticky_note_2",
            detailsIcon: "summarize",
            domainTranslationPrefix: "fact-sheet",
            forBusinessPartner: true,
            standAloneDomain: true,
            hideMenuItem: true,
            baseUrl: "factsheets",
            sideNavTier: 2,
            components: {
                contextMenuHeader: FactSheetContextMenuHeader,
            },
        }),
        ////
        EntitySeparatorSubModule(FactSheetsDomainId, {
            subModuleId: SiteSeparator,
            displayTitle: "domains.fact-sheets.separators.site-information",
        }),
        FactSheetModule(FactSheetsDomainId),
        CompanyInformationSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        PostalAddressSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "Site_address" }),
        SiteNavigationSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        AccessRestrictionsSubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "Site_accessRestrictions",
        }),
        OpeningHoursSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "Site_openingHours" }),
        SiteAdditionalInformationSubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "Site_additionalInformation",
        }),
        ProcessesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "Site_processes" }),
        WeighingBridgesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ParkingSpaceBasicSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        InfrastructuresNearbySubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        CheckInSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        GatesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        GeneralSafetyRulesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        TrafficRelatedRulesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        SafetyTestSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        AlarmsAndEmergencySubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        SiteIncidentManagementSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ////
        EntitySeparatorSubModule(FactSheetsDomainId, {
            subModuleId: UnloadingPointSeparator,
            displayTitle: "domains.fact-sheets.separators.ulp-information",
        }),
        UlpBasicInformationSubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "UnloadingPoint_basicInformation",
        }),
        PostalAddressSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "UnloadingPoint_address" }),
        AccessRestrictionsSubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "UnloadingPoint_accessRestrictions",
        }),
        RegistrationSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        OpeningHoursSubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "UnloadingPoint_openingHours",
        }),
        ContactDataSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ProcessesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "UnloadingPoint_processes" }),
        UnLoadingAreaSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        WorkingOnTopSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        AvailableEquipmentSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        AvailableEquipmentPackedSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ReceivingTankSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        SlotBookingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ShipmentPreNotificationSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ////
        VehicleAndPPESubModule(FactSheetsDomainId, {
            moduleId: FactSheetModuleId,
            subModuleId: "AssignedProduct_vehicleAndPersonalProtectiveEquipment",
        }),
        VehicleSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        EquipmentPackedSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        TankSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ConnectionsSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        HoseSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        PumpSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        CompressorSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        VapourReturnSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        CargoSecuringSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        TemperatureRequirementsSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        PackagingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        PalletsSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        DocumentsSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        GeneralInstructionsSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        WeighingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        FillingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        SealingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        SamplingSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId }),
        ProcessesSubModule(FactSheetsDomainId, { moduleId: FactSheetModuleId, subModuleId: "AssignedProduct_processes" }),
        ////
    ],
    providers: [
        {
            token: TranslationPrefixToken,
            useValue: "domains.fact-sheets",
        },
    ],
});
