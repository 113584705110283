import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { CleaningRequirementsModuleId } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditGeneralCleaningInfoComponent from "@/app/sub-modules/general-cleaning-info/components/edit-general-cleaning-info.component.vue";
import DisplayGeneralCleaningInfoComponent from "@/app/sub-modules/general-cleaning-info/components/display-general-cleaning-info.component.vue";
import DisplayGeneralCleaningInfoViewComponent from "@/app/sub-modules/general-cleaning-info/components/display-general-cleaning-info-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const GeneralCleaningInfoSubModule = (domainId, options) => {
    const id = "generalCleaningInfo";
    const moduleId = options?.moduleId ?? CleaningRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditGeneralCleaningInfoComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayGeneralCleaningInfoComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayGeneralCleaningInfoViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "info",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.general-cleaning-info",
            },
        ],
    });
};
