import { __decorate } from "tslib";
import { service } from "@/di/decorators/service.decorator";
let ModuleDataInterceptor = class ModuleDataInterceptor {
    async interceptResponse(config, response) {
        const factSheetModuleUrl = config?.url?.endsWith("/factsheet");
        if (factSheetModuleUrl) {
            if (!response.data.Site_address) {
                response.data = mapData(response);
            }
        }
        return response;
    }
};
ModuleDataInterceptor = __decorate([
    service()
], ModuleDataInterceptor);
export { ModuleDataInterceptor };
const mapData = (result) => {
    const site = result.data.site;
    const ulp = result.data.unloadingPoint;
    const product = result.data.product;
    return {
        siteInformation: site?.generalInformation?.siteInformation,
        Site_address: site?.generalInformation?.address,
        siteNavigation: site?.generalInformation?.siteNavigation,
        Site_processes: site?.generalInformation?.processes,
        Site_openingHours: site?.generalInformation?.openingHours,
        Site_additionalInformation: site?.generalInformation?.additionalInformation,
        Site_accessRestrictions: site?.generalInformation?.accessRestrictions,
        nearbyInfrastructures: site?.facilities?.nearbyInfrastructures,
        parkingSpaces: site?.facilities?.parkingSpaces,
        weighingBridges: site?.facilities?.weighingBridges,
        checkIns: site?.checkIn?.checkIns,
        gates: site?.gates?.gates,
        alarmAndEmergencyProcedures: site?.safetyAndSecurity?.alarmAndEmergencyProcedures,
        generalSafetyRules: site?.safetyAndSecurity?.generalSafetyRules,
        safetyTest: site?.safetyAndSecurity?.safetyTest,
        siteIncidentManagement: site?.safetyAndSecurity?.siteIncidentManagement,
        trafficRules: site?.safetyAndSecurity?.trafficRules,
        //
        UnloadingPoint_basicInformation: ulp?.generalInformation?.basicInformation,
        UnloadingPoint_address: ulp?.generalInformation?.address,
        UnloadingPoint_accessRestrictions: ulp?.generalInformation?.accessRestrictions,
        registration: ulp?.generalInformation?.registration,
        UnloadingPoint_openingHours: ulp?.generalInformation?.openingHours,
        contactData: ulp?.generalInformation?.contactData,
        UnloadingPoint_processes: ulp?.generalInformation?.processes,
        unloadingLoadingArea: ulp?.installations?.unloadingLoadingArea,
        availableEquipment: ulp?.installations?.availableEquipment,
        availableEquipmentPacked: ulp?.installations?.availableEquipmentPacked,
        receivingTank: ulp?.installations?.receivingTank,
        workingOnTop: ulp?.installations?.workingOnTop,
        shipmentPreNotification: ulp?.slotBooking?.shipmentPreNotification,
        slotBooking: ulp?.slotBooking?.slotBooking,
        //
        vehicleAndPersonalProtectiveEquipment: product?.safetyAndSecurity.vehicleAndPersonalProtectiveEquipment,
        temperatureRequirements: product?.technicalRequirements?.temperatureRequirements,
        vehicle: product?.technicalRequirements?.vehicle,
        tank: product?.technicalRequirements?.tank,
        connections: product?.technicalRequirements?.connections,
        hose: product?.technicalRequirements?.hose,
        pump: product?.technicalRequirements?.pump,
        compressor: product?.technicalRequirements?.compressor,
        vapourReturn: product?.technicalRequirements?.vapourReturn,
        equipmentPacked: product?.technicalRequirements?.equipmentPacked,
        cargoSecuring: product?.technicalRequirements?.cargoSecuring,
        packaging: product?.packaging?.packaging,
        pallets: product?.packaging?.pallets,
        documents: product?.documents?.documents,
        generalInstructions: product?.instructions?.generalInstructions,
        weighing: product?.instructions?.weighing,
        filling: product?.instructions?.filling,
        sealing: product?.instructions?.sealing,
        sampling: product?.instructions?.sampling,
        AssignedProduct_processes: product?.instructions?.processes,
    };
};
