import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e84e074"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "fact-sheet-page-header" };
const _hoisted_2 = { class: "company" };
const _hoisted_3 = { class: "sub-title" };
const _hoisted_4 = { class: "logo-name-container" };
const _hoisted_5 = { class: "entity-name" };
const _hoisted_6 = { class: "product" };
const _hoisted_7 = { class: "sub-title" };
const _hoisted_8 = { class: "entity-name" };
const _hoisted_9 = { class: "verification" };
const _hoisted_10 = { class: "sub-title" };
const _hoisted_11 = { class: "entity-name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_Badge = _resolveComponent("Badge");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("requesting-company")), 1),
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Thumbnail, {
                    src: _ctx.entity.companyFromLogoPath,
                    icon: "domain",
                    size: "lg"
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.entity.companyFromName), 1)
            ])
        ]),
        _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("product-name")), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.entity.inboundProductName), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translate("status-verification")), 1),
            _createElementVNode("div", _hoisted_11, [
                (_ctx.entity.statusVerification)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 0,
                        color: _ctx.verificationStatusStyles[_ctx.entity.statusVerification]
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getStatus(_ctx.entity.statusVerification)), 1)
                        ]),
                        _: 1
                    }, 8, ["color"]))
                    : _createCommentVNode("", true)
            ])
        ])
    ]));
}
