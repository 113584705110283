import { createModule } from "@/di/module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { GeneralInformationModuleId } from "@/app/modules/general-information/general-information.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditProductGroupAssignmentComponent from "@/app/sub-modules/product-group-assignment/components/edit-product-group-assignment.component.vue";
import DisplayProductGroupAssignmentComponent from "@/app/sub-modules/product-group-assignment/components/display-product-group-assignment.component.vue";
import DisplayProductGroupAssignmentViewComponent from "@/app/sub-modules/product-group-assignment/components/display-product-group-assignment-view.component.vue";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
export const ProductGroupAssignmentSubModule = (domainId, options) => {
    const id = "productGroupAssignment";
    const moduleId = options?.moduleId ?? GeneralInformationModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditProductGroupAssignmentComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayProductGroupAssignmentComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayProductGroupAssignmentViewComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, displayViewComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "assignment_turned_in",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                    displayView: displayViewComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.product-group-assignment",
            },
        ],
    });
};
