import { resolve } from "@/di/composables/resolve";
import { RouterService } from "@/app/shared/services/router.service";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { VerificationRequestsSummaryDomainId } from "@/app/domains/summary/summary.constants";
import _ from "lodash";
import { FactSheetsDomainId } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
export const useDomainTranslationPrefix = (domainId) => {
    const routerService = resolve(RouterService);
    domainId = domainId ? domainId : routerService.getCurrentRoute()?.meta?.domainId;
    const metaData = resolve(domainTokensFactory(domainId).meta);
    const unloadingKeysDomains = [VerificationRequestsSummaryDomainId, FactSheetsDomainId];
    return (key, strict) => {
        if (!strict) {
            if (metaData.id?.includes("Loading")) {
                return `loading-${key}`;
            }
            else if (metaData.id?.includes("Unloading") || unloadingKeysDomains.includes(metaData.id)) {
                return `unloading-${key}`;
            }
            return `site-${key}`;
        }
        const prefix = _.isFunction(metaData.domainTranslationPrefix)
            ? metaData.domainTranslationPrefix(routerService)
            : metaData.domainTranslationPrefix;
        return `${prefix}-${key}`;
    };
};
