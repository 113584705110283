import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplayCargoSecuring from "@/app/sub-modules/cargo-securing/components/display-cargo-securing.vue";
import DisplayCargoSecuringView from "@/app/sub-modules/cargo-securing/components/display-cargo-securing-view.vue";
import EditCargoSecuring from "@/app/sub-modules/cargo-securing/components/edit-cargo-securing.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
export const CargoSecuringSubModule = (domainId, options) => {
    const id = "cargoSecuring";
    const moduleId = options?.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayCargoSecuring, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayCargoSecuringView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditCargoSecuring, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "vpn_lock",
                showChangeHistory: true,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.cargo-securing",
            },
        ],
    });
};
