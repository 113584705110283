import { camelCase } from "change-case";
import { createModule } from "@/di/module";
import { CargoSecuringSubModule } from "@/app/sub-modules/cargo-securing/cargo-securing.module";
import { CleaningOperatorsSubModule } from "@/app/sub-modules/cleaning-operators/cleaning-operators.module";
import { CleaningRequirementsModule } from "@/app/modules/cleaning-requirements/cleaning-requirements.module";
import { CompressorSubModule } from "@/app/sub-modules/compressor/compressor.module";
import { ConnectionsSubModule } from "@/app/sub-modules/connections/connections.module";
import { DgClassificationSubModule } from "@/app/sub-modules/dg-classification/dg-classification.module";
import { DocumentsModule, DocumentsModuleId } from "@/app/modules/documents/documents.module";
import { DocumentsSubModule } from "@/app/sub-modules/documents/documents.module";
import { DomainModule } from "@/app/modular-content/modules/domain.module";
import { EquipmentPackedSubModule } from "@/app/sub-modules/equipment-packed/equipment-packed.module";
import { EuropeanCleaningDocumentSubModule } from "@/app/sub-modules/european-cleaning-document/european-cleaning-document.module";
import { FillingSubModule } from "@/app/sub-modules/filling/filling.module";
import { GeneralCleaningInfoSubModule } from "@/app/sub-modules/general-cleaning-info/general-cleaning-info.module";
import { GeneralInformationModule } from "@/app/modules/general-information/general-information.module";
import { GeneralInstructionsSubModule } from "@/app/sub-modules/general-instructions/general-instructions.module";
import { HoseSubModule } from "@/app/sub-modules/hose/hose.module";
import { InstructionsModule } from "@/app/modules/instructions/instructions.module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { PackagingModule } from "@/app/modules/packaging/packaging.module";
import { PackagingSubModule } from "@/app/sub-modules/packaging/packaging.module";
import { PalletsSubModule } from "@/app/sub-modules/pallets/pallets.module";
import { PreloadConfirmationSubModule } from "@/app/sub-modules/preload-confirmation/preload-confirmation.module";
import { PreloadingRestrictionsModule } from "@/app/domains/preloading-restrictions/preloading-restrictions.module";
import { PRLRestrictionsSettingsSubModule } from "@/app/sub-modules/prl-restrictions-settings/prl-restrictions-settings.module";
import { ProcessesSubModule } from "@/app/sub-modules/processes/processes.module";
import { ProductAdditionalInformationSubModule } from "@/app/sub-modules/additional-information/product/product-additional-information.module";
import { ProductBasicInformationSubModule } from "@/app/sub-modules/basic-information/product/product-basic-information.module";
import { ProductGroupAssignmentSubModule } from "@/app/sub-modules/product-group-assignment/product-group-assignment.module";
import { ProductParametersSubModule } from "@/app/sub-modules/product-parameters/product-parameters.module";
import { ProductsSafetyModule } from "@/app/modules/products-safety/products-safety.module";
import { PumpSubModule } from "@/app/sub-modules/pump/pump.module";
import { RequiredEquipmentStateSubModule } from "@/app/sub-modules/required-equipment-state/required-equipment-state.module";
import { SafetyAndSecurityModule, SafetyAndSecurityModuleId, } from "@/app/modules/safety-and-security/safety-and-security.module";
import { SafetyDataSheetSubModule } from "@/app/sub-modules/safety-data-sheet/safety-data-sheet.module";
import { SamplingSubModule } from "@/app/sub-modules/sampling/sampling.module";
import { SealingSubModule } from "@/app/sub-modules/sealing/sealing.module";
import { SpecialProductRequirementsSubModule } from "@/app/sub-modules/special-product-requirements/special-product-requirements.module";
import { SummaryModule } from "@/app/domains/summary/summary.module";
import { TankSubModule } from "@/app/sub-modules/tank/tank.module";
import { TechnicalRequirementsModule } from "@/app/modules/technical-requirements/technical-requirements.module";
import { TemperatureRequirementsSubModule } from "@/app/sub-modules/temperature-requirements/temperature-requirements.module";
import { VacuumTestConfirmationSubModule } from "@/app/sub-modules/vacuum-test-confirmation/vacuum-test-confirmation.module";
import { VapourReturnSubModule } from "@/app/sub-modules/vapour-return/vapour-return.module";
import { VehicleAndPPESubModule } from "@/app/sub-modules/vehicle-and-ppe/vehicle-and-ppe.module";
import { VehicleSubModule } from "@/app/sub-modules/vehicle/vehicle.module";
import { WeighingSubModule } from "@/app/sub-modules/weighing/weighing.module";
import { AssignedEntitiesService } from "@/app/domains/assigned-products/services/assigned-entities.service";
import { AssignedProductService } from "@/app/domains/assigned-products/services/assigned-product.service";
import { CopyAssignedProductsModulesService } from "@/app/domains/assigned-products/services/copy-assigned-modules.service";
import { SearchService } from "@/app/modular-content/services/search.service";
import { SelectedLPFilterService } from "@/app/domains/assigned-products/services/selected-lp-filter.service";
import { SelectedULPFilterService } from "@/app/domains/assigned-products/services/selected-ulp-filter.service";
import AssignedProductsContextMenuHeaderComponent from "@/app/domains/assigned-products/components/assigned-products-context-menu-header.component.vue";
import AssignedProductsTableComponent from "@/app/domains/assigned-products/components/assigned-products-table.component.vue";
import AssignEntitiesModalComponent from "@/app/domains/assigned-products/components/assign-entities-modal.component.vue";
import AssignProductMasterModalComponent from "@/app/domains/assigned-products/components/assign-product-master-modal.component.vue";
import { domainTokensFactory } from "@/app/modular-content/factories/domain-tokens.factory";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { AssignedClusterSearchServiceToken, AssignedProductClustersListId, } from "@/app/domains/assigned-product-clusters/assigned-product-cluster.constants";
import { AssignedEntitiesServiceToken, AssignedProductDomainServiceToken, AssignedProductMasterSearchServiceToken, AssignedProductSearchServiceToken, AssignedProductsListId, AssignProductMasterModalId, AssignProductMasterModalIdToken, UnloadingLoadingPointFilterServiceToken, } from "@/app/domains/assigned-products/assigned-products.constants";
import { LoadingPointAssignedProductMastersDomainId, UnloadingPointAssignedProductMastersDomainId, } from "@/app/domains/assigned-product-masters/assigned-product-masters.constants";
import { LoadingPointDomainId } from "@/app/domains/loading-points/loading-points.constants";
import { PreloadingRestrictionsDomainId } from "@/app/domains/preloading-restrictions/preloading-restrictions.constants";
import { ProductDomainId } from "@/app/domains/products/products.constants";
import { RouteMetaListId } from "@/app/modular-content/modular-content.constants";
import { SummaryDomainId } from "@/app/domains/summary/summary.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { UnloadingPointDomainId } from "@/app/domains/unloading-points/unloading-points.constants";
const ParentDomainBaseUrlEnum = {
    [LoadingPointDomainId]: "loadingpoints",
    [UnloadingPointDomainId]: "unloadingpoints",
};
const ParentDomainFilterTableEnum = {
    [LoadingPointDomainId]: SelectedLPFilterService,
    [UnloadingPointDomainId]: SelectedULPFilterService,
};
const ParentDomainAssignedProductMastersDomainEnum = {
    [LoadingPointDomainId]: LoadingPointAssignedProductMastersDomainId,
    [UnloadingPointDomainId]: UnloadingPointAssignedProductMastersDomainId,
};
const entityByListId = {
    [AssignedProductsListId]: "products",
    [AssignedProductClustersListId]: "productclusters",
};
export const AssignedProductsModule = (parentDomainId, id) => {
    const componentSuffixes = [parentDomainId, id];
    const contextMenuHeaderComponent = suffixComponentName(AssignedProductsContextMenuHeaderComponent, ...componentSuffixes);
    const createModalComponent = suffixComponentName(AssignEntitiesModalComponent, ...componentSuffixes);
    const tableComponent = suffixComponentName(AssignedProductsTableComponent, ...componentSuffixes);
    const assignProductMasterModalComponent = suffixComponentName(AssignProductMasterModalComponent, ...componentSuffixes);
    const tableFilter = ParentDomainFilterTableEnum[parentDomainId];
    const assignedProductMastersDomainId = ParentDomainAssignedProductMastersDomainEnum[parentDomainId];
    const copyTooltipTranslationKey = "domains.assigned-products.components.copy-module-modal.tooltip";
    return createModule({
        components: [contextMenuHeaderComponent, createModalComponent, tableComponent, assignProductMasterModalComponent],
        imports: [
            createModule({
                imports: [
                    DomainModule({
                        parent: parentDomainId,
                        id: id,
                        icon: "science",
                        detailsIcon: "science",
                        lists: [
                            {
                                id: "all",
                                default: true,
                                filter: tableFilter,
                            },
                            {
                                id: AssignedProductClustersListId,
                                filter: tableFilter,
                                hideForBusinessPartners: true,
                            },
                        ],
                        createBtnLabel: (rs) => {
                            const listId = rs?.getMeta(RouteMetaListId) ?? "";
                            return `domains.assigned-products.assign-button.${listId}`;
                        },
                        domainTranslationPrefix: "assigned-products",
                        forBusinessPartner: true,
                        baseUrl: (routerService) => {
                            const listId = routerService?.getMeta(RouteMetaListId);
                            const paramId = camelCase(parentDomainId + "Id");
                            return listId && entityByListId[listId]
                                ? `${ParentDomainBaseUrlEnum[parentDomainId]}/:${paramId}/${entityByListId[listId]}`
                                : `${ParentDomainBaseUrlEnum[parentDomainId]}/:${paramId}/products`;
                        },
                        components: {
                            contextMenuHeader: contextMenuHeaderComponent,
                            createModal: createModalComponent,
                            table: tableComponent,
                        },
                    }),
                    //
                    GeneralInformationModule(id, { readOnly: true, originDomain: ProductDomainId }),
                    ProductBasicInformationSubModule(id),
                    ProductParametersSubModule(id),
                    ProductGroupAssignmentSubModule(id),
                    ProductAdditionalInformationSubModule(id),
                    //
                    ProductsSafetyModule(id, { readOnly: true, originDomain: ProductDomainId }),
                    PRLRestrictionsSettingsSubModule(id),
                    DgClassificationSubModule(id),
                    SpecialProductRequirementsSubModule(id),
                    SafetyDataSheetSubModule(id),
                    //
                    CleaningRequirementsModule(id, { readOnly: true, originDomain: ProductDomainId }),
                    GeneralCleaningInfoSubModule(id),
                    EuropeanCleaningDocumentSubModule(id),
                    PreloadConfirmationSubModule(id),
                    VacuumTestConfirmationSubModule(id),
                    RequiredEquipmentStateSubModule(id),
                    CleaningOperatorsSubModule(id),
                    //
                    SafetyAndSecurityModule(id, {
                        navigationIcon: "svg/assigned_products",
                        copyAvailable: true,
                        copyTooltipTranslationKey,
                    }),
                    VehicleAndPPESubModule(id, { moduleId: SafetyAndSecurityModuleId }),
                    //
                    TechnicalRequirementsModule(id, {
                        navigationIcon: "svg/assigned_products",
                        copyAvailable: true,
                        copyTooltipTranslationKey,
                    }),
                    VehicleSubModule(id),
                    EquipmentPackedSubModule(id, { showChangeHistory: true }),
                    TankSubModule(id, { showChangeHistory: true }),
                    ConnectionsSubModule(id),
                    HoseSubModule(id),
                    PumpSubModule(id),
                    CompressorSubModule(id),
                    VapourReturnSubModule(id),
                    CargoSecuringSubModule(id),
                    TemperatureRequirementsSubModule(id),
                    //
                    PackagingModule(id, {
                        navigationIcon: "svg/assigned_products",
                        copyAvailable: true,
                        copyTooltipTranslationKey,
                    }),
                    PackagingSubModule(id),
                    PalletsSubModule(id),
                    //
                    DocumentsModule(id, {
                        navigationIcon: "svg/assigned_products",
                        copyAvailable: true,
                        copyTooltipTranslationKey,
                    }),
                    DocumentsSubModule(id, { moduleId: DocumentsModuleId }),
                    //
                    InstructionsModule(id, {
                        navigationIcon: "svg/assigned_products",
                        copyAvailable: true,
                        copyTooltipTranslationKey,
                    }),
                    GeneralInstructionsSubModule(id),
                    WeighingSubModule(id),
                    FillingSubModule(id),
                    SealingSubModule(id),
                    SamplingSubModule(id),
                    ProcessesSubModule(id),
                    //
                    ModalModule(id + AssignProductMasterModalId, assignProductMasterModalComponent),
                    //
                    createModule({
                        imports: [
                            PreloadingRestrictionsModule(id, id + PreloadingRestrictionsDomainId, {
                                readOnly: true,
                                originDomain: PreloadingRestrictionsDomainId,
                            }),
                        ],
                    }),
                    SummaryModule(id, id + SummaryDomainId, { public: true }),
                ],
                providers: [
                    { token: TranslationPrefixToken, useValue: "domains.assigned-products" },
                    { token: AssignedProductDomainServiceToken, useToken: domainTokensFactory(id).service },
                    { token: id + AssignedProductSearchServiceToken, useClass: SearchService },
                    { token: SearchService, useToken: id + AssignedProductSearchServiceToken },
                    { token: id + AssignedClusterSearchServiceToken, useClass: SearchService },
                    { token: SearchService, useToken: id + AssignedClusterSearchServiceToken },
                    { token: id + AssignedEntitiesServiceToken, useClass: AssignedEntitiesService },
                    { token: AssignedEntitiesService, useToken: id + AssignedEntitiesServiceToken },
                    {
                        token: AssignedProductMasterSearchServiceToken,
                        useToken: domainTokensFactory(assignedProductMastersDomainId).search,
                    },
                    { token: UnloadingLoadingPointFilterServiceToken, useClass: tableFilter },
                    { token: AssignProductMasterModalIdToken, useValue: id + AssignProductMasterModalId },
                    AssignedProductService,
                    CopyAssignedProductsModulesService,
                ],
            }),
        ],
    });
};
