import { createModule } from "@/di/module";
import { ModalModule } from "@/app/modals/modules/modal.module";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import EditAlarmsAndEmergencyComponent from "@/app/sub-modules/alarms-and-emargency/components/edit-alarms-and-emergency.component.vue";
import DisplayAlarmsAndEmergencyComponent from "@/app/sub-modules/alarms-and-emargency/components/display-alarms-and-emergency.component.vue";
import AttachmentModalComponent from "@/app/shared/components/attachment-widget/attachment-modal.component.vue";
import { AttachmentModalId } from "@/app/shared/components/attachment-widget/attachment.constants";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { SafetyAndSecurityModuleId } from "@/app/modules/safety-and-security/safety-and-security.module";
export const AlarmsAndEmergencySubModule = (domainId, options) => {
    const id = "alarmAndEmergencyProcedures";
    const moduleId = options?.moduleId ?? SafetyAndSecurityModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditAlarmsAndEmergencyComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayAlarmsAndEmergencyComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent, AttachmentModalComponent],
        imports: [
            ModalModule(AttachmentModalId, AttachmentModalComponent),
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "alarm",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.alarm-and-emergency-procedures" }],
    });
};
