import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import EditAvailableEquipmentComponent from "@/app/sub-modules/available-equipment/components/edit-available-equipment.component.vue";
import DisplayAvailableEquipmentComponent from "@/app/sub-modules/available-equipment/components/display-available-equipment.component.vue";
import { InstallationsModuleId } from "@/app/modules/installations/installations.module";
export const AvailableEquipmentSubModule = (domainId, options) => {
    const id = "availableEquipment";
    const moduleId = options?.moduleId ?? InstallationsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const editComponent = suffixComponentName(EditAvailableEquipmentComponent, ...componentSuffixes);
    const displayComponent = suffixComponentName(DisplayAvailableEquipmentComponent, ...componentSuffixes);
    return createModule({
        components: [editComponent, displayComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "home_repair_service",
                components: {
                    edit: editComponent,
                    display: displayComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.available-equipment",
            },
        ],
    });
};
