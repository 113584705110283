import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { ProductsSafetyModuleId } from "@/app/modules/products-safety/products-safety.module";
import DisplayPRLRestrictionsSettingsComponent from "@/app/sub-modules/prl-restrictions-settings/components/display-prl-restrictions-settings.component.vue";
import DisplayPRLRestrictionsSettingsViewComponent from "@/app/sub-modules/prl-restrictions-settings/components/display-prl-restrictions-settings-view.component.vue";
import EditPRLRestrictionsSettingsComponent from "@/app/sub-modules/prl-restrictions-settings/components/edit-prl-restrictions-settings.component.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
export const PRLRestrictionsSettingsSubModule = (domainId, options) => {
    const id = "prlRestrictionsSettings";
    const moduleId = options?.moduleId ?? ProductsSafetyModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayPRLRestrictionsSettingsComponent, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayPRLRestrictionsSettingsViewComponent, ...componentSuffixes);
    const editComponent = suffixComponentName(EditPRLRestrictionsSettingsComponent, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "not_interested",
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [{ token: TranslationPrefixToken, useValue: "sub-modules.prl-restrictions-settings" }],
    });
};
