import { createModule } from "@/di/module";
import ModularContentComponent from "@/app/modular-content/modular-content.component.vue";
import { NullEntityTableFilterService } from "@/app/modular-content/services/null-entity-table-filter.service";
import { ModuleDataInterceptor } from "./services/module-data-interceptor.service";
import { HttpResponseInterceptorToken } from "../http/tokens/http-response-interceptor.token";
export const ModularContentModule = createModule({
    components: [ModularContentComponent],
    providers: [
        NullEntityTableFilterService,
        ModuleDataInterceptor,
        { token: HttpResponseInterceptorToken, useToken: ModuleDataInterceptor },
    ],
});
