import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import { createModule } from "@/di/module";
import EntitySeparator from "@/app/sub-modules/separators/entity-separator/entity-separator.component.vue";
import { FactSheetModuleId } from "@/app/domains/verification-requests/fact-sheets/fact-sheets.constants";
export const EntitySeparatorSubModule = (domainId, options) => {
    const id = options.subModuleId ?? "";
    const moduleId = FactSheetModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const entitySeparatorComponent = suffixComponentName(EntitySeparator, ...componentSuffixes);
    return createModule({
        components: [entitySeparatorComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "",
                displayTitle: options?.displayTitle,
                hideDisplayTitle: true,
                components: {
                    display: entitySeparatorComponent,
                    edit: entitySeparatorComponent,
                },
            }),
        ],
    });
};
