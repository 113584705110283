import { suffixComponentName } from "@/app/modular-content/functions/suffix-component-name";
import { SubModuleModule } from "@/app/modular-content/modules/sub-module.module";
import DisplayTank from "@/app/sub-modules/tank/components/display-tank.vue";
import DisplayTankView from "@/app/sub-modules/tank/components/display-tank-view.vue";
import EditTank from "@/app/sub-modules/tank/components/edit-tank.vue";
import { TranslationPrefixToken } from "@/app/translation/tokens/translation-prefix.token";
import { createModule } from "@/di/module";
import { TechnicalRequirementsModuleId } from "@/app/modules/technical-requirements/technical-requirements.module";
export const TankSubModule = (domainId, options) => {
    const id = "tank";
    const moduleId = options?.moduleId ?? TechnicalRequirementsModuleId;
    const componentSuffixes = [domainId, moduleId, id];
    const displayComponent = suffixComponentName(DisplayTank, ...componentSuffixes);
    const displayViewComponent = suffixComponentName(DisplayTankView, ...componentSuffixes);
    const editComponent = suffixComponentName(EditTank, ...componentSuffixes);
    return createModule({
        components: [displayComponent, displayViewComponent, editComponent],
        imports: [
            SubModuleModule({
                id,
                moduleId,
                domainId,
                icon: "signal_wifi_statusbar_connected_no_internet_4",
                showChangeHistory: options?.showChangeHistory,
                components: {
                    display: displayComponent,
                    displayView: displayViewComponent,
                    edit: editComponent,
                },
            }),
        ],
        providers: [
            {
                token: TranslationPrefixToken,
                useValue: "sub-modules.tank",
            },
        ],
    });
};
